var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("img", { staticClass: "banner", attrs: { src: _vm.bannerUrl } }),
      _c(
        "div",
        { staticClass: "order-info" },
        [
          _c("div", { staticClass: "order-info-title border flex f-ai-c pr" }, [
            _c("img", {
              staticClass: "icon",
              attrs: { src: require("@/assets/images/icon-1.png") },
            }),
            _c("span", { staticClass: "title" }, [_vm._v("预约信息")]),
            _vm.editDisable
              ? _c(
                  "span",
                  {
                    staticClass: "tips t1",
                    style: {
                      fontWeight: [3, 8].includes(_vm.dataForm.status)
                        ? "600"
                        : "500",
                      fontSize: [3, 8].includes(_vm.dataForm.status)
                        ? "16px"
                        : "12px",
                    },
                  },
                  [
                    _vm._v(
                      "(" +
                        _vm._s(
                          {
                            1: "待处理",
                            3: "已完成",
                            7: "已取消",
                            8: "已爽约",
                            9: "已过期",
                          }[_vm.dataForm.status]
                        ) +
                        ")"
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "a",
              {
                staticClass: "show-detail",
                staticStyle: { "font-size": "12px" },
                on: {
                  click: function ($event) {
                    _vm.showOverlay = true
                  },
                },
              },
              [_vm._v("服务介绍")]
            ),
          ]),
          _vm.dataForm.status === 8
            ? _c("span", { staticClass: "sy-tips" }, [
                _vm._v(
                  "【已爽约】您已预约 " +
                    _vm._s(
                      _vm.service === "greenChannel"
                        ? "指定疾病住院协助"
                        : "术后上门护理"
                    ) +
                    " 服务，但未在约定时间" +
                    _vm._s(_vm.service === "greenChannel" ? "就医" : "使用") +
                    "，视为服务完成，本保单年度不可再申请本服务。 "
                ),
              ])
            : _vm._e(),
          _c(
            "van-form",
            { ref: "form", attrs: { "validate-first": "" } },
            [
              _c("van-field", {
                attrs: {
                  label: "被保人姓名",
                  placeholder: "请输入姓名",
                  rules: _vm.nameRul,
                  clearable: "",
                  required: "",
                  disabled: "",
                },
                model: {
                  value: _vm.dataForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "name", $$v)
                  },
                  expression: "dataForm.name",
                },
              }),
              _c("van-field", {
                attrs: {
                  label: "被保人证件号",
                  placeholder: "请输入证件号",
                  clearable: "",
                  required: "",
                  disabled: "",
                },
                model: {
                  value: _vm.dataForm.credentialsNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "credentialsNo", $$v)
                  },
                  expression: "dataForm.credentialsNo",
                },
              }),
              _c("van-field", {
                attrs: {
                  label: "申请人姓名",
                  placeholder: "请输入姓名",
                  clearable: "",
                  required: "",
                  maxlength: 30,
                  rules: _vm.nameRul,
                  disabled: _vm.editDisable,
                },
                model: {
                  value: _vm.dataForm.applyName,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "applyName", $$v)
                  },
                  expression: "dataForm.applyName",
                },
              }),
              _c("van-field", {
                attrs: {
                  label: "申请人证件号",
                  placeholder: "请输入证件号",
                  clearable: "",
                  required: "",
                  rules: _vm.idCardRul,
                  disabled: _vm.editDisable,
                },
                model: {
                  value: _vm.dataForm.applyCredentialsNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "applyCredentialsNo", $$v)
                  },
                  expression: "dataForm.applyCredentialsNo",
                },
              }),
              _c("van-field", {
                attrs: {
                  label: "申请人手机号",
                  maxlength: 11,
                  placeholder: "请输入手机号",
                  rules: _vm.applyPhoneRul,
                  clearable: "",
                  required: "",
                  disabled: _vm.editDisable,
                },
                model: {
                  value: _vm.dataForm.applyPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "applyPhone", $$v)
                  },
                  expression: "dataForm.applyPhone",
                },
              }),
              _c("van-field", {
                attrs: {
                  readonly: "",
                  clickable: "",
                  name: "picker",
                  label: "与被保人关系",
                  placeholder: "请选择",
                  required: "",
                  rules: _vm.relationRul,
                  value: _vm.dataForm.relation,
                  "right-icon": require("@/assets/images/icon-right.png"),
                  disabled: _vm.editDisable,
                },
                on: {
                  click: function ($event) {
                    _vm.showRelationPicker = true
                  },
                },
              }),
              _c(
                "van-popup",
                {
                  attrs: { position: "bottom" },
                  model: {
                    value: _vm.showRelationPicker,
                    callback: function ($$v) {
                      _vm.showRelationPicker = $$v
                    },
                    expression: "showRelationPicker",
                  },
                },
                [
                  _c("van-picker", {
                    attrs: { "show-toolbar": "", columns: _vm.relationList },
                    on: {
                      confirm: _vm.onServiceTypeConfirm,
                      cancel: function ($event) {
                        _vm.showRelationPicker = false
                      },
                    },
                  }),
                ],
                1
              ),
              _c("van-field", {
                attrs: {
                  readonly: "",
                  clickable: "",
                  name: "picker",
                  label:
                    _vm.service !== "greenChannel"
                      ? "期望服务时间"
                      : "期望就诊时间",
                  placeholder: "请选择",
                  required: "",
                  value: _vm.dataForm.expServiceTime,
                  rules: _vm.expServiceTimeRul,
                  "right-icon": require("@/assets/images/icon-right.png"),
                  disabled: _vm.editDisable,
                },
                on: {
                  click: function ($event) {
                    _vm.showExpectTimePicker = true
                  },
                },
              }),
              _c(
                "van-popup",
                {
                  attrs: { position: "bottom" },
                  model: {
                    value: _vm.showExpectTimePicker,
                    callback: function ($$v) {
                      _vm.showExpectTimePicker = $$v
                    },
                    expression: "showExpectTimePicker",
                  },
                },
                [
                  _c("div", { staticClass: "flex f-d-c" }, [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass: "ex-btn",
                          on: { click: _vm.confirmExpTime },
                        },
                        [_vm._v("确认")]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { width: "100%", height: "70vh" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "ex-date" },
                          _vm._l(_vm.dateArray, function (date, index) {
                            return _c(
                              "span",
                              {
                                key: index,
                                staticClass: "box",
                                class: {
                                  active: date.value === _vm.dataForm.expDate,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDateClick(date)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(date.text))]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm.dataForm.service !== "greenChannel"
                ? _c("van-field", {
                    attrs: {
                      readonly: "",
                      clickable: "",
                      name: "picker",
                      label: "服务项目",
                      placeholder: "请选择",
                      required: "",
                      value: _vm.dataForm.serviceItemName,
                      "right-icon": require("@/assets/images/icon-right.png"),
                      disabled: _vm.editDisable,
                    },
                    on: {
                      click: function ($event) {
                        _vm.showServiceItemPicker = true
                      },
                    },
                  })
                : _vm._e(),
              _c(
                "van-popup",
                {
                  attrs: { position: "bottom" },
                  model: {
                    value: _vm.showServiceItemPicker,
                    callback: function ($$v) {
                      _vm.showServiceItemPicker = $$v
                    },
                    expression: "showServiceItemPicker",
                  },
                },
                [
                  _c("van-picker", {
                    attrs: { "show-toolbar": "", columns: _vm.serviceItemList },
                    on: {
                      confirm: _vm.onServiceItemConfirm,
                      cancel: function ($event) {
                        _vm.showServiceItemPicker = false
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.dataForm.service !== "greenChannel"
                ? _c("van-field", {
                    staticClass: "marital-status",
                    attrs: { name: "radio", label: "性别", required: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "input",
                          fn: function () {
                            return [
                              _c(
                                "van-radio-group",
                                {
                                  attrs: {
                                    direction: "horizontal",
                                    "icon-size": "16px",
                                    disabled: _vm.editDisable,
                                  },
                                  model: {
                                    value: _vm.dataForm.gender,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "gender", $$v)
                                    },
                                    expression: "dataForm.gender",
                                  },
                                },
                                [
                                  _c("van-radio", { attrs: { name: 1 } }, [
                                    _vm._v("男"),
                                  ]),
                                  _c("van-radio", { attrs: { name: 2 } }, [
                                    _vm._v("女"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2320806393
                    ),
                  })
                : _vm._e(),
              _vm.dataForm.service !== "greenChannel"
                ? _c("van-field", {
                    attrs: {
                      readonly: "",
                      clickable: "",
                      name: "picker",
                      label: "地址信息",
                      placeholder: "请选择",
                      required: "",
                      rules: _vm.addressTextRul,
                      value: _vm.dataForm.addressText,
                      "right-icon": require("@/assets/images/icon-right.png"),
                      disabled: _vm.editDisable,
                    },
                    on: {
                      click: function ($event) {
                        _vm.showAddressPicker = true
                      },
                    },
                  })
                : _vm._e(),
              _c(
                "van-popup",
                {
                  attrs: { position: "bottom" },
                  model: {
                    value: _vm.showAddressPicker,
                    callback: function ($$v) {
                      _vm.showAddressPicker = $$v
                    },
                    expression: "showAddressPicker",
                  },
                },
                [
                  _c("van-area", {
                    attrs: { title: "请选择", "area-list": _vm.areaList },
                    on: {
                      confirm: _vm.addressConfirm,
                      cancel: function ($event) {
                        _vm.showAddressPicker = false
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.dataForm.service !== "greenChannel"
                ? _c("van-field", {
                    attrs: {
                      label: "详细地址",
                      placeholder: "请输入详细地址",
                      clearable: "",
                      "show-word-limit": "",
                      autosize: "",
                      rows: "2",
                      type: "textarea",
                      required: "",
                      rules: _vm.addressDetailRul,
                      maxlength: 50,
                      disabled: _vm.editDisable,
                    },
                    model: {
                      value: _vm.dataForm.addressDetail,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "addressDetail", $$v)
                      },
                      expression: "dataForm.addressDetail",
                    },
                  })
                : _vm._e(),
              _vm.dataForm.service !== "greenChannel"
                ? _c("van-field", {
                    attrs: {
                      label: "备注",
                      placeholder: "请输入备注",
                      clearable: "",
                      disabled: _vm.editDisable,
                    },
                    model: {
                      value: _vm.dataForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "remark", $$v)
                      },
                      expression: "dataForm.remark",
                    },
                  })
                : _vm._e(),
              _vm.dataForm.service === "greenChannel"
                ? _c("van-field", {
                    attrs: {
                      label: "期望就诊城市",
                      placeholder: "请输入就诊城市",
                      clearable: "",
                      maxlength: 30,
                      required: "",
                      rules: _vm.nowHospitalRul,
                      disabled: _vm.editDisable,
                    },
                    model: {
                      value: _vm.dataForm.nowHospital,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "nowHospital", $$v)
                      },
                      expression: "dataForm.nowHospital",
                    },
                  })
                : _vm._e(),
              _vm.dataForm.service === "greenChannel"
                ? _c("van-field", {
                    attrs: {
                      label: "期望就诊医院",
                      placeholder: "请输入期望就诊医院",
                      maxlength: 30,
                      clearable: "",
                      required: "",
                      rules: _vm.hospitalRul,
                      disabled: _vm.editDisable,
                    },
                    model: {
                      value: _vm.dataForm.hospital,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "hospital", $$v)
                      },
                      expression: "dataForm.hospital",
                    },
                  })
                : _vm._e(),
              _vm.dataForm.service === "greenChannel"
                ? _c("van-field", {
                    attrs: {
                      label: "疾病诊断",
                      placeholder: "请输入疾病诊断",
                      clearable: "",
                      required: "",
                      "show-word-limit": "",
                      autosize: "",
                      rows: "2",
                      type: "textarea",
                      maxlength: 30,
                      rules: _vm.diseaseDiagnosisRul,
                      disabled: _vm.editDisable,
                    },
                    model: {
                      value: _vm.dataForm.diseaseDiagnosis,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "diseaseDiagnosis", $$v)
                      },
                      expression: "dataForm.diseaseDiagnosis",
                    },
                  })
                : _vm._e(),
              _vm.dataForm.service === "greenChannel"
                ? _c("van-field", {
                    attrs: {
                      label: "目前所在城市",
                      placeholder: "请输入目前所在城市",
                      clearable: "",
                      maxlength: 30,
                      disabled: _vm.editDisable,
                    },
                    model: {
                      value: _vm.dataForm.nowCity,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "nowCity", $$v)
                      },
                      expression: "dataForm.nowCity",
                    },
                  })
                : _vm._e(),
              _vm.dataForm.service === "greenChannel"
                ? _c("van-field", {
                    staticClass: "marital-status",
                    attrs: { name: "radio", label: "是否已接受治疗" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "input",
                          fn: function () {
                            return [
                              _c(
                                "van-radio-group",
                                {
                                  attrs: {
                                    direction: "horizontal",
                                    "icon-size": "16px",
                                    disabled: _vm.editDisable,
                                  },
                                  model: {
                                    value: _vm.dataForm.cured,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "cured", $$v)
                                    },
                                    expression: "dataForm.cured",
                                  },
                                },
                                [
                                  _c("van-radio", { attrs: { name: 1 } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("van-radio", { attrs: { name: 0 } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1856940844
                    ),
                  })
                : _vm._e(),
              _vm.dataForm.service === "greenChannel"
                ? _c("van-field", {
                    staticClass: "marital-status",
                    attrs: { name: "radio", label: "是否使用医保" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "input",
                          fn: function () {
                            return [
                              _c(
                                "van-radio-group",
                                {
                                  attrs: {
                                    direction: "horizontal",
                                    "icon-size": "16px",
                                    disabled: _vm.editDisable,
                                  },
                                  model: {
                                    value: _vm.dataForm.usedHealthInsurance,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "usedHealthInsurance",
                                        $$v
                                      )
                                    },
                                    expression: "dataForm.usedHealthInsurance",
                                  },
                                },
                                [
                                  _c("van-radio", { attrs: { name: 1 } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("van-radio", { attrs: { name: 0 } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      491929160
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "order-info" }, [
        _c("div", { staticClass: "order-info-title border flex f-ai-c" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/icon-2.png") },
          }),
          _c("span", { staticClass: "title" }, [_vm._v("门诊病历(最多15张)")]),
        ]),
        _c(
          "div",
          { staticClass: "border card-border" },
          [
            _c("div", { staticStyle: { height: "10px", width: "100%" } }),
            !_vm.editDisable
              ? _c("van-uploader", {
                  attrs: {
                    "max-size": (file) =>
                      _vm.onOversize(file, "patientMedicalRecords"),
                    "max-count": 15,
                  },
                  on: {
                    delete: (event, index) =>
                      _vm.deleteFile(event, index, "patientMedicalRecords"),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "preview-cover",
                        fn: function ({ file }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "preview-cover van-ellipsis" },
                              [_vm._v(_vm._s(file.name))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2890202680
                  ),
                  model: {
                    value: _vm.patientMedicalRecordsList,
                    callback: function ($$v) {
                      _vm.patientMedicalRecordsList = $$v
                    },
                    expression: "patientMedicalRecordsList",
                  },
                })
              : _c(
                  "div",
                  { staticClass: "vimg" },
                  _vm._l(
                    _vm.dataForm.patientMedicalRecords,
                    function (item, index) {
                      return _c("van-image", {
                        key: index,
                        attrs: {
                          width: "6rem",
                          height: "6rem",
                          fit: "contain",
                          src: item.url,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.previewImage("patientMedicalRecords")
                          },
                        },
                      })
                    }
                  ),
                  1
                ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "order-info" }, [
        _c("div", { staticClass: "order-info-title border flex f-ai-c" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/icon-2.png") },
          }),
          _c("span", { staticClass: "title" }, [
            _vm._v("住院通知单(最多15张)"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "border card-border" },
          [
            _c("div", { staticStyle: { height: "10px", width: "100%" } }),
            !_vm.editDisable
              ? _c("van-uploader", {
                  attrs: {
                    "max-size": (file) =>
                      _vm.onOversize(file, "dischargeSummary"),
                    "max-count": 15,
                  },
                  on: {
                    delete: (event, index) =>
                      _vm.deleteFile(event, index, "dischargeSummary"),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "preview-cover",
                        fn: function ({ file }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "preview-cover van-ellipsis" },
                              [_vm._v(_vm._s(file.name))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2890202680
                  ),
                  model: {
                    value: _vm.dischargeSummaryList,
                    callback: function ($$v) {
                      _vm.dischargeSummaryList = $$v
                    },
                    expression: "dischargeSummaryList",
                  },
                })
              : _c(
                  "div",
                  { staticClass: "vimg" },
                  _vm._l(_vm.dataForm.dischargeSummary, function (item, index) {
                    return _c("van-image", {
                      key: index,
                      attrs: {
                        width: "6rem",
                        height: "6rem",
                        fit: "contain",
                        src: item.url,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.previewImage("dischargeSummary")
                        },
                      },
                    })
                  }),
                  1
                ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "order-info" }, [
        _c("div", { staticClass: "order-info-title border flex f-ai-c" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/icon-2.png") },
          }),
          _c("span", { staticClass: "title" }, [
            _vm._v("血液检测报告(最多15张)"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "border card-border" },
          [
            _c("div", { staticStyle: { height: "10px", width: "100%" } }),
            !_vm.editDisable
              ? _c("van-uploader", {
                  attrs: {
                    "max-size": (file) =>
                      _vm.onOversize(file, "bloodTestReport"),
                    "max-count": 15,
                  },
                  on: {
                    delete: (event, index) =>
                      _vm.deleteFile(event, index, "bloodTestReport"),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "preview-cover",
                        fn: function ({ file }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "preview-cover van-ellipsis" },
                              [_vm._v(_vm._s(file.name))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2890202680
                  ),
                  model: {
                    value: _vm.bloodTestReportList,
                    callback: function ($$v) {
                      _vm.bloodTestReportList = $$v
                    },
                    expression: "bloodTestReportList",
                  },
                })
              : _c(
                  "div",
                  { staticClass: "vimg" },
                  _vm._l(_vm.dataForm.bloodTestReport, function (item, index) {
                    return _c("van-image", {
                      key: index,
                      attrs: {
                        width: "6rem",
                        height: "6rem",
                        fit: "contain",
                        src: item.url,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.previewImage("bloodTestReport")
                        },
                      },
                    })
                  }),
                  1
                ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "order-info" }, [
        _c("div", { staticClass: "order-info-title border flex f-ai-c" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/icon-2.png") },
          }),
          _c("span", { staticClass: "title" }, [_vm._v("CT报告(最多15张)")]),
        ]),
        _c(
          "div",
          { staticClass: "border card-border" },
          [
            _c("div", { staticStyle: { height: "10px", width: "100%" } }),
            !_vm.editDisable
              ? _c("van-uploader", {
                  attrs: {
                    "max-size": (file) => _vm.onOversize(file, "ct"),
                    "max-count": 15,
                  },
                  on: {
                    delete: (event, index) =>
                      _vm.deleteFile(event, index, "ct"),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "preview-cover",
                        fn: function ({ file }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "preview-cover van-ellipsis" },
                              [_vm._v(_vm._s(file.name))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2890202680
                  ),
                  model: {
                    value: _vm.ctList,
                    callback: function ($$v) {
                      _vm.ctList = $$v
                    },
                    expression: "ctList",
                  },
                })
              : _c(
                  "div",
                  { staticClass: "vimg" },
                  _vm._l(_vm.dataForm.ct, function (item, index) {
                    return _c("van-image", {
                      key: index,
                      attrs: {
                        width: "6rem",
                        height: "6rem",
                        fit: "contain",
                        src: item.url,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.previewImage("ct")
                        },
                      },
                    })
                  }),
                  1
                ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "order-info" }, [
        _c("div", { staticClass: "order-info-title border flex f-ai-c" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/icon-2.png") },
          }),
          _c("span", { staticClass: "title" }, [
            _vm._v("MRI或病理报告(最多15张)"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "border card-border" },
          [
            _c("div", { staticStyle: { height: "10px", width: "100%" } }),
            !_vm.editDisable
              ? _c("van-uploader", {
                  attrs: {
                    "max-size": (file) => _vm.onOversize(file, "mri"),
                    "max-count": 15,
                  },
                  on: {
                    delete: (event, index) =>
                      _vm.deleteFile(event, index, "mri"),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "preview-cover",
                        fn: function ({ file }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "preview-cover van-ellipsis" },
                              [_vm._v(_vm._s(file.name))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2890202680
                  ),
                  model: {
                    value: _vm.mriList,
                    callback: function ($$v) {
                      _vm.mriList = $$v
                    },
                    expression: "mriList",
                  },
                })
              : _c(
                  "div",
                  { staticClass: "vimg" },
                  _vm._l(_vm.dataForm.mri, function (item, index) {
                    return _c("van-image", {
                      key: index,
                      attrs: {
                        width: "6rem",
                        height: "6rem",
                        fit: "contain",
                        src: item.url,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.previewImage("mri")
                        },
                      },
                    })
                  }),
                  1
                ),
          ],
          1
        ),
      ]),
      !_vm.editDisable
        ? _c(
            "div",
            {
              staticClass: "button flex f-jc-c",
              attrs: { "native-type": "submit" },
              on: { click: _vm.commit },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/button.png"), alt: "" },
              }),
              _c("span", [_vm._v("提交")]),
            ]
          )
        : _vm._e(),
      _c(
        "van-popup",
        {
          attrs: { round: "", position: "bottom", closeable: "" },
          model: {
            value: _vm.showOverlay,
            callback: function ($$v) {
              _vm.showOverlay = $$v
            },
            expression: "showOverlay",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "wrapper pr",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("h1", [_vm._v("服务介绍")]),
              _c(
                "ul",
                { staticClass: "service-intro" },
                _vm._l(_vm.serviceIntro, function (item, index) {
                  return _c("li", { key: index }, [_vm._v(_vm._s(item))])
                }),
                0
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }