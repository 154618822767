<template>
	<div class="container">
		<img class="banner" :src="bannerUrl"/>
		<div class="order-info">
			<div class="order-info-title border flex f-ai-c pr">
				<img class="icon" :src="require('@/assets/images/icon-1.png')"/>
				<span class="title">预约信息</span>
				<span
                    v-if="editDisable"
                    class="tips t1"
                    :style="{
                        fontWeight: [3, 8].includes(dataForm.status) ? '600' : '500',
                        fontSize: [3, 8].includes(dataForm.status) ? '16px' : '12px'
                    }"
                >({{ ({ 1: '待处理', 3: '已完成', 7: '已取消', 8: '已爽约', 9: '已过期' })[dataForm.status] }})</span>
                <a class="show-detail" @click="showOverlay = true" style="font-size: 12px;">服务介绍</a>
			</div>
            <span v-if="dataForm.status === 8" class="sy-tips">【已爽约】您已预约 {{service === 'greenChannel' ? '指定疾病住院协助' : '术后上门护理'}} 服务，但未在约定时间{{service === 'greenChannel' ? '就医' : '使用'}}，视为服务完成，本保单年度不可再申请本服务。 </span>
			<van-form validate-first ref="form">
                <!-- TODO: 绿通 + 术后 公有 -- START -->
				<van-field v-model="dataForm.name" label="被保人姓名" placeholder="请输入姓名" :rules="nameRul" clearable required disabled />
				<van-field v-model="dataForm.credentialsNo" label="被保人证件号" placeholder="请输入证件号" clearable required disabled/>
				<van-field v-model="dataForm.applyName" label="申请人姓名" placeholder="请输入姓名" clearable required :maxlength="30" :rules="nameRul" :disabled="editDisable" />
				<van-field v-model="dataForm.applyCredentialsNo" label="申请人证件号" placeholder="请输入证件号" clearable required :rules="idCardRul" :disabled="editDisable" />
				<van-field v-model="dataForm.applyPhone" label="申请人手机号" :maxlength="11" placeholder="请输入手机号" :rules="applyPhoneRul" clearable required :disabled="editDisable" />
				<van-field
					readonly
					clickable
					name="picker"
					label="与被保人关系"
					placeholder="请选择"
                    required
					@click="showRelationPicker = true"
                    :rules="relationRul"
					:value="dataForm.relation"
					:right-icon="require('@/assets/images/icon-right.png')"
                    :disabled="editDisable"
				/>
                <van-popup v-model="showRelationPicker" position="bottom">
					<van-picker
						show-toolbar
						:columns="relationList"
						@confirm="onServiceTypeConfirm"
						@cancel="showRelationPicker = false"
					/>
				</van-popup>
                <van-field
					readonly
					clickable
					name="picker"
					:label="service !== 'greenChannel' ? '期望服务时间' : '期望就诊时间'"
					placeholder="请选择"
					required
					@click="showExpectTimePicker = true"
					:value="dataForm.expServiceTime"
                    :rules="expServiceTimeRul"
					:right-icon="require('@/assets/images/icon-right.png')"
                    :disabled="editDisable"
				/>
                <van-popup v-model="showExpectTimePicker" position="bottom">
					<div class="flex f-d-c">
                        <div>
                            <span class="ex-btn" @click="confirmExpTime">确认</span>
                        </div>
                        <div class="flex" style="width: 100%; height: 70vh;">
                            <div class="ex-date">
                                <span
                                    class="box"
                                    v-for="(date, index) in dateArray"
                                    :key="index"
                                    :class="{ active: date.value === dataForm.expDate }"
                                    @click="onDateClick(date)"
                                >{{date.text}}</span>
                            </div>
                            <!-- <div class="ex-time">
                                <span
                                    class="box"
                                    v-for="(time, index) in timeArray"
                                    :key="index"
                                    :class="{ active: time === dataForm.expTime }"
                                    @click="onTimeClick(time)"
                                >{{time}}</span>
                            </div> -->
                        </div>
                    </div>
				</van-popup>
                <!-- TODO: 绿通 + 术后 公有 -- END -->

                <!-- TODO: 术后 独有 -- START -->
                <van-field
                    v-if="dataForm.service !== 'greenChannel'"
					readonly
					clickable
					name="picker"
					label="服务项目"
					placeholder="请选择"
					required
					@click="showServiceItemPicker = true"
					:value="dataForm.serviceItemName"
					:right-icon="require('@/assets/images/icon-right.png')"
                    :disabled="editDisable"
				/>
                <van-popup v-model="showServiceItemPicker" position="bottom">
					<van-picker
						show-toolbar
						:columns="serviceItemList"
						@confirm="onServiceItemConfirm"
						@cancel="showServiceItemPicker = false"
					/>
				</van-popup>
                <van-field v-if="dataForm.service !== 'greenChannel'" name="radio" label="性别" class="marital-status" required>
					<template #input>
						<van-radio-group v-model="dataForm.gender" direction="horizontal" icon-size="16px" :disabled="editDisable">
							<van-radio :name="1">男</van-radio>
							<van-radio :name="2">女</van-radio>
						</van-radio-group>
					</template>
				</van-field>
                <van-field
                    v-if="dataForm.service !== 'greenChannel'"
					readonly
					clickable
					name="picker"
					label="地址信息"
					placeholder="请选择"
					@click="showAddressPicker = true"
                    required
                    :rules="addressTextRul"
					:value="dataForm.addressText"
					:right-icon="require('@/assets/images/icon-right.png')"
                    :disabled="editDisable"
				/>
                <van-popup v-model="showAddressPicker" position="bottom">
					<van-area title="请选择" :area-list="areaList" @confirm="addressConfirm" @cancel="showAddressPicker = false;"/>
				</van-popup>
                <van-field
                    v-if="dataForm.service !== 'greenChannel'"
                    v-model="dataForm.addressDetail"
                    label="详细地址"
                    placeholder="请输入详细地址"
                    clearable
                    show-word-limit
                    autosize
                    rows="2"
                    type="textarea"
                    required
                    :rules="addressDetailRul"
                    :maxlength="50"
                    :disabled="editDisable"
                />
				<van-field v-if="dataForm.service !== 'greenChannel'" v-model="dataForm.remark" label="备注" placeholder="请输入备注" clearable :disabled="editDisable"/>
                <!-- TODO: 术后 独有 -- END -->
                <!-- TODO: 绿通独有字段 -- START -->
                <van-field v-if="dataForm.service === 'greenChannel'" v-model="dataForm.nowHospital" label="期望就诊城市" placeholder="请输入就诊城市" clearable :maxlength="30" required :rules="nowHospitalRul" :disabled="editDisable" />
                <van-field v-if="dataForm.service === 'greenChannel'" v-model="dataForm.hospital" label="期望就诊医院" placeholder="请输入期望就诊医院" :maxlength="30" clearable required :rules="hospitalRul" :disabled="editDisable" />
                <van-field
                    v-if="dataForm.service === 'greenChannel'"
                    v-model="dataForm.diseaseDiagnosis"
                    label="疾病诊断"
                    placeholder="请输入疾病诊断"
                    clearable
                    required
                    show-word-limit
                    autosize
                    rows="2"
                    type="textarea"
                    :maxlength="30"
                    :rules="diseaseDiagnosisRul"
                    :disabled="editDisable"
                />
                <van-field v-if="dataForm.service === 'greenChannel'" v-model="dataForm.nowCity" label="目前所在城市" placeholder="请输入目前所在城市" clearable :maxlength="30" :disabled="editDisable" />
                <van-field v-if="dataForm.service === 'greenChannel'" name="radio" label="是否已接受治疗" class="marital-status">
					<template #input>
						<van-radio-group v-model="dataForm.cured" direction="horizontal" icon-size="16px" :disabled="editDisable">
							<van-radio :name="1">是</van-radio>
							<van-radio :name="0">否</van-radio>
						</van-radio-group>
					</template>
				</van-field>
                <van-field v-if="dataForm.service === 'greenChannel'" name="radio" label="是否使用医保" class="marital-status">
					<template #input>
						<van-radio-group v-model="dataForm.usedHealthInsurance" direction="horizontal" icon-size="16px" :disabled="editDisable">
							<van-radio :name="1">是</van-radio>
							<van-radio :name="0">否</van-radio>
						</van-radio-group>
					</template>
				</van-field>
                <!-- TODO: 绿通独有字段 -- END  -->
			</van-form>
		</div>
        <div class="order-info">
			<div class="order-info-title border flex f-ai-c">
				<img class="icon" :src="require('@/assets/images/icon-2.png')"/>
				<span class="title">门诊病历(最多15张)</span>
			</div>
			<div class="border card-border">
				<div style="height: 10px; width: 100%;"></div>
                <van-uploader
                    v-if="!editDisable"
                    v-model="patientMedicalRecordsList"
                    :max-size="(file) => onOversize(file, 'patientMedicalRecords')"
                    :max-count="15"
                    @delete="(event, index) => deleteFile(event, index, 'patientMedicalRecords')"
                >
                    <template #preview-cover="{ file }">
                        <div class="preview-cover van-ellipsis">{{ file.name }}</div>
                    </template>
                </van-uploader>
                <div class="vimg" v-else>
                    <van-image
                        width="6rem"
                        height="6rem"
                        fit="contain"
                        v-for="(item, index) in dataForm.patientMedicalRecords"
                        :key="index"
                        :src="item.url"
                        @click="previewImage('patientMedicalRecords')"
                    />
                </div>
			</div>
        </div>
        <div class="order-info">
			<div class="order-info-title border flex f-ai-c">
				<img class="icon" :src="require('@/assets/images/icon-2.png')"/>
				<span class="title">住院通知单(最多15张)</span>
			</div>
			<div class="border card-border">
				<div style="height: 10px; width: 100%;"></div>
                <van-uploader
                    v-if="!editDisable"
                    v-model="dischargeSummaryList"
                    :max-size="(file) => onOversize(file, 'dischargeSummary')"
                    :max-count="15"
                    @delete="(event, index) => deleteFile(event, index, 'dischargeSummary')"
                >
                    <template #preview-cover="{ file }">
                        <div class="preview-cover van-ellipsis">{{ file.name }}</div>
                    </template>
                </van-uploader>
                <div class="vimg" v-else>
                    <van-image
                        width="6rem"
                        height="6rem"
                        fit="contain"
                        v-for="(item, index) in dataForm.dischargeSummary"
                        :key="index"
                        :src="item.url"
                        @click="previewImage('dischargeSummary')"
                    />
                </div>
			</div>
        </div>
        <div class="order-info">
			<div class="order-info-title border flex f-ai-c">
				<img class="icon" :src="require('@/assets/images/icon-2.png')"/>
				<span class="title">血液检测报告(最多15张)</span>
			</div>
			<div class="border card-border">
				<div style="height: 10px; width: 100%;"></div>
                <van-uploader
                    v-if="!editDisable"
                    v-model="bloodTestReportList"
                    :max-size="(file) => onOversize(file, 'bloodTestReport')"
                    :max-count="15"
                    @delete="(event, index) => deleteFile(event, index, 'bloodTestReport')"
                >
                    <template #preview-cover="{ file }">
                        <div class="preview-cover van-ellipsis">{{ file.name }}</div>
                    </template>
                </van-uploader>
                <div class="vimg" v-else>
                    <van-image
                        width="6rem"
                        height="6rem"
                        fit="contain"
                        v-for="(item, index) in dataForm.bloodTestReport"
                        :key="index"
                        :src="item.url"
                        @click="previewImage('bloodTestReport')"
                    />
                </div>
			</div>
        </div>
        <div class="order-info">
			<div class="order-info-title border flex f-ai-c">
				<img class="icon" :src="require('@/assets/images/icon-2.png')"/>
				<span class="title">CT报告(最多15张)</span>
			</div>
			<div class="border card-border">
				<div style="height: 10px; width: 100%;"></div>
                <van-uploader
                    v-if="!editDisable"
                    v-model="ctList"
                    :max-size="(file) => onOversize(file, 'ct')"
                    :max-count="15"
                    @delete="(event, index) => deleteFile(event, index, 'ct')"
                >
                    <template #preview-cover="{ file }">
                        <div class="preview-cover van-ellipsis">{{ file.name }}</div>
                    </template>
                </van-uploader>
                <div class="vimg" v-else>
                    <van-image
                        width="6rem"
                        height="6rem"
                        fit="contain"
                        v-for="(item, index) in dataForm.ct"
                        :key="index"
                        :src="item.url"
                        @click="previewImage('ct')"
                    />
                </div>
			</div>
        </div>
        <div class="order-info">
			<div class="order-info-title border flex f-ai-c">
				<img class="icon" :src="require('@/assets/images/icon-2.png')"/>
				<span class="title">MRI或病理报告(最多15张)</span>
			</div>
			<div class="border card-border">
				<div style="height: 10px; width: 100%;"></div>
                <van-uploader
                    v-if="!editDisable"
                    v-model="mriList"
                    :max-size="(file) => onOversize(file, 'mri')"
                    :max-count="15"
                    @delete="(event, index) => deleteFile(event, index, 'mri')"
                >
                    <template #preview-cover="{ file }">
                        <div class="preview-cover van-ellipsis">{{ file.name }}</div>
                    </template>
                </van-uploader>
                <div class="vimg" v-else>
                    <van-image
                        width="6rem"
                        height="6rem"
                        fit="contain"
                        v-for="(item, index) in dataForm.mri"
                        :key="index"
                        :src="item.url"
                        @click="previewImage('mri')"
                    />
                </div>
			</div>
        </div>
		<div class="button flex f-jc-c" @click="commit" native-type="submit" v-if="!editDisable">
			<img :src="require('@/assets/images/button.png')" alt="">
			<span>提交</span>
		</div>
        <van-popup v-model="showOverlay" round position="bottom" closeable  >
            <div class="wrapper pr" @click.stop>
                <h1>服务介绍</h1>
                <ul class="service-intro">
                    <li v-for="(item, index) in serviceIntro" :key="index">{{item}}</li>
                </ul>
            </div>
        </van-popup>
	</div>
</template>
<script>
import smbLtBanner from '@/assets/images/smb-lt.jpg';
import smbShsmBanner from '@/assets/images/smb-shsm.jpg';
import { areaList } from '@vant/area-data';
import { validatePhone/* , validateIdCard */ } from '@/utils';
import peorg from '@/lab/peorg';
import areas from '@/lab/area';
import { addOrderSMB, getOrderSMB, getDecode } from '@/request/api';
import { Toast, ImagePreview } from 'vant';
import Compressor from 'compressorjs';
import OSS from 'ali-oss';
import md5 from 'md5';
import { aliossToken } from '@/request/api/alioss';

const applyPhoneValidator = function (value) {
	return validatePhone(value);
};
const dayMap = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
export default {
	name: 'smbHome',
	data () {
		/* const idCardValidator = function (value) {
			return validateIdCard(value);
		}; */
		return {
			title: '',
			areaList,
			bannerUrl: null,
			areas,
			orglist: peorg,
			dataForm: {
				addressText: '',
				address: [],
				addressDetail: '',
				name: '',
				credentialsNo: '',
				applyName: '',
				applyCredentialsNo: '',
				applyPhone: '',
				relation: '',
				useTime: '',
				gender: 1,
				serviceItemName: '',
				serviceItemValue: 0,
				expDate: '',
				expTime: '',
				expServiceTime: '',
				patientMedicalRecords: [],
				dischargeSummary: [],
				bloodTestReport: [],
				ct: [],
				mri: [],
				diseaseDiagnosis: '',
				nowCity: '',
				nowHospital: '',
				hospital: '',
				cured: '',
				usedHealthInsurance: ''
			},
			patientMedicalRecordsList: [],
			dischargeSummaryList: [],
			bloodTestReportList: [],
			ctList: [],
			mriList: [],
			showOverlay: false,
			showRelationPicker: false,
			showServiceItemPicker: false,
			showAddressPicker: false,
			showExpectTimePicker: false,
			relationList: ['配偶', '子女', '父母', '本人'],
			serviceItemList: [
				{ text: '1.普通换药', value: 1 },
				{ text: '2.雾化治疗', value: 2 },
				{ text: '3.会阴护理', value: 3 },
				{ text: '4.灌肠护理', value: 4 },
				{ text: '5.口腔护理', value: 5 },
				{ text: '6.造口护理', value: 6 },
				{ text: '7.吸痰护理', value: 7 },
				{ text: '8.压疮护理', value: 8 },
				{ text: '9.更换尿管及管路维护', value: 9 },
				{ text: '10.更换胃管及鼻饲', value: 10 },
				{ text: '11.PICC管路维护及换药', value: 11 }
			],
			nameRul: [{ required: true, message: '请填写姓名', name: 'name' }],
			useTimeRul: [{ required: true, message: '请填写使用时间' }],
			relationRul: [{ required: true, message: '请选择与被保人关系' }],
			// expServiceTimeRul: [{ required: true, message: `请选择期望${serviceType === 'greenChannel' ? '就诊' : '服务'}时间` }],
			hospitalRul: [{ required: true, message: '请填写期望就诊医院' }],
			nowHospitalRul: [{ required: true, message: '请填写期望就诊城市' }],
			diseaseDiagnosisRul: [{ required: true, message: '请填写诊断内容' }],
			idCardRul: [{ required: true, message: '请填写证件号' }],
			addressDetailRul: [{ required: true, message: '请填写详细地址' }],
			addressTextRul: [{ required: true, message: '请选择地质信息' }],
			applyPhoneRul: [
				{ required: true, validator: applyPhoneValidator, message: '请填写正确的手机号' }
			],
			canSubmit: true,
			editDisable: false,
			dateArray: [],
			timeArray: [
				'08:00 ~ 08:30',
				'08:30 ~ 09:00',
				'09:00 ~ 09:30',
				'09:30 ~ 10:00',
				'10:00 ~ 10:30',
				'10:30 ~ 11:00',
				'11:00 ~ 11:30',
				'11:30 ~ 12:00',
				'12:00 ~ 12:30',
				'12:30 ~ 13:00',
				'13:00 ~ 13:30',
				'13:30 ~ 14:00',
				'14:00 ~ 14:30',
				'14:30 ~ 15:00',
				'15:00 ~ 15:30',
				'15:30 ~ 16:00',
				'16:00 ~ 16:30',
				'16:30 ~ 17:00',
				'17:00 ~ 17:30',
				'17:30 ~ 18:00',
				'18:00 ~ 18:30',
				'18:30 ~ 19:00',
				'19:00 ~ 19:30',
				'19:30 ~ 20:00'
			],
			serviceIntro: [],
			service: ''
		};
	},
	created () {
		this.service = this.$route.query.service;
		this.expServiceTimeRul = [{ required: true, message: `请选择期望${this.service === 'greenChannel' ? '就诊' : '服务'}时间` }];
		if (this.service === 'greenChannel') {
			this.title = '重疾住院协助';
			this.bannerUrl = smbLtBanner;
			this.serviceIntro = [
				'符合使用条件的被保险人，在保单有效期内罹患保障范围内的重大疾病且有就医需求时，可申请三甲医院的住院安排服务（可指定医院，但不可指定医生），减少住院等待时间。',
				'【服务次数】保单年度内1次；',
				'【服务时效】被保险人通过服务资格审核、相关资料齐全且已开具住院单的，在申请通过之日起10个工作日内安排；',
				'【医院范围】精选全国31个省/直辖市1000+三甲医院；',
				'【申请材料】包括但不限于门诊病历、住院通知单、血液检测报告、CT 报告、MRI 报告或病理报告等，具体资料会依据不同疾病而有所不同；',
				'【注意事项】若服务商已为被保险人完成住院安排，但被保险人未在预定时间就医，视为本次服务已完成；'
			];
		} else {
			this.bannerUrl = smbShsmBanner;
			this.title = '术后上门护理服务';
			this.serviceIntro = [
				'符合使用条件的被保险人，在保单有效期内因意外接受住院手术治疗或因罹患本保险产品条款保障范围内的重大疾病住院治疗出院后仍有护理需求的，可申请专业的术后居家护理服务，提高康复效率和生活质量。',
				'【服务时效】服务申请审核通过后，2个工作日内安排预约服务；',
				'【服务次数】保单有效期内1次；',
				'【城市范围】覆盖全国30个省/直辖市的430个城市；',
				'【申请材料】包括但不限于门诊病历、出院小结、血液检测报告、CT报告、MRI 报告或病理报告等，具体资料会依据不同疾病而有所不同；',
				'【注意事项】1、被保险人需提前2个工作日申请且仅可预约申请通过后7天内的服务时间；2、若服务商已为被保险人完成服务安排，但被保险人未在预定时间使用，视为本次服务已完成；'
			];
		};
		const _query = this.$route.query;
		this.dataForm = {
			...this.dataForm,
			..._query
		};
		if (_query.customerOrderNo) {
			this.getDetail(_query.customerOrderNo); // 24市民保那边传过来的唯一值
		} else {
			alert('参数错误，缺少订单号');
			this.editDisable = true;
		}
		this.initDateArray();
	},
	mounted () {
		document.title = this.title;
	},
	methods: {
		previewImage (type) {
			ImagePreview({
				images: this.dataForm[type].map(item => item.url),
				closeable: true
			});
		},
		addressConfirm (address) {
			this.showAddressPicker = false;
			this.dataForm.address = address.map(a => a.code);
			this.dataForm.addressText = address.map(a => a.name).join(' ');
		},
		deleteFile (event, index, type) {
			console.log(event, index);
			this.dataForm[type].splice(index.index, 1);
		},
		onOversize (file, type) {
			let flag = false;
			const that = this;
			const a = new Compressor(file, {
				quality: 0.8,
				success (result) {
					if (result.size / 1024 / 1024 / 5 > 1) {
						Toast('图片大小不能超过5M');
					} else {
						flag = true;
						that.getTokenAndUpload(result, (res) => {
							if (res) {
								that.dataForm[type].push({ name: res.name, url: res.url });
							}
						});
					}
					return flag;
				}
			});
			console.log(a);
		},
		async getTokenAndUpload (file, cb) {
			if (!this.alossInfo) {
				this.alossInfo = await aliossToken();
			}
			const imgType = file.type.split('/')[1];
			const filename = file.name + file.size; // md5对图片名称进行加密
			const keyValue = '' + md5(new Date() + filename) + '.' + imgType;
			const client = new OSS({
				region: this.alossInfo.region,
				stsToken: this.alossInfo.SecurityToken,
				accessKeyId: this.alossInfo.AccessKeyId,
				accessKeySecret: this.alossInfo.AccessKeySecret,
				bucket: this.alossInfo.bucket
			});
			client
				.put('vip-vas-public/' + keyValue, file)
				.then((res) => {
					if (res.url) {
						file.url = res.url;
						file.message = '';
						cb(res);
					} else {
						Toast('图片上传失败', 'error');
						cb();
					}
				})
				.catch((err) => {
					console.error('message', err.message);
					Toast('图片上传失败', 'error');
					cb();
				});
		},
		confirmExpTime () {
			this.showExpectTimePicker = false;
			this.dataForm.expServiceTime = `${this.dataForm.expDate} ${this.dataForm.expTime}`;
		},
		onTimeClick (time) {
			this.dataForm.expTime = time;
		},
		onDateClick (date) {
			this.dataForm.expDate = date.value;
		},
		initDateArray () {
			let start = 1; let length = 15;
			if (this.service !== 'greenChannel') {
				start = 2;
				length = 7;
			}
			for (let i = start; i < length + start; i++) {
				const date = new Date(Date.now() + i * 24 * 3600000);
				let month = date.getMonth() + 1;
				month = month < 10 ? `0${month}` : month;
				let _date = date.getDate();
				_date = _date < 10 ? `0${_date}` : _date;
				this.dateArray.push({
					// text: `${month}月${_date}日 ${!i ? '今天' : i === 1 ? '明天' : dayMap[date.getDay()]}`,
					text: `${month}月${_date}日 ${dayMap[date.getDay()]}`,
					value: `${date.getFullYear()}-${month}-${_date}`
				});
			}
		},
		async getDecode () {
			try {
				let result = await getDecode({ params: this.$route.query.params });
				if (result) {
					result = JSON.parse(result);
					this.dataForm = {
						...this.dataForm,
						...result
					};
				}
			} catch (e) {
				alert('参数解析失败');
				console.error(e);
			}
		},
		getDetail (code) {
			getOrderSMB({ code }).then(data => {
				if (data) {
					const areaMap = ['province_list', 'city_list', 'county_list'];
					this.editDisable = [1, 3, 8].includes(data.status); // 待处理、已完成、已爽约 状态下不可编辑
					this.dataForm = {
						...this.dataForm,
						...data.detail,
						status: data.status,
						relation: this.relationList[data.detail.relation - 1],
						addressText: data.detail.address ? data.detail.address.map((a, index) => areaList[areaMap[index]][a]).join(' ') : ''
					};
					if (data.status === 1 && data.detail.serviceEndTime && new Date() > new Date(`${data.detail.serviceEndTime} 23:59:59`)) {
						this.dataForm.status = 9; // TODO: 这个状态只存在于H5前端
					}
				} else {
					this.getDecode();
				}
			});
		},
		commit () {
			if (this.canSubmit) {
				this.$refs.form.validate().then(data => {
					this.canSubmit = false;
					addOrderSMB({
						...this.dataForm,
						relation: this.relationList.indexOf(this.dataForm.relation) + 1
					}).then(data => {
						this.canSubmit = true;
						if (data && data.ok === 1) {
							this.$root.go('/success?type=smb');
						}
					});
				}).catch(e => {});
			}
		},
		onServiceTypeConfirm (val) {
			this.dataForm.relation = val;
			this.showRelationPicker = false;
		},
		onServiceItemConfirm (val) {
			this.dataForm.serviceItemName = val.text;
			this.dataForm.serviceItemValue = val.value;
			this.showServiceItemPicker = false;
		}
	}
};
</script>
<style lang="less" scoped>
    ::v-deep .van-popup--bottom.van-popup--round {
        border-radius: 8px 8px 0 0;
    }
	.border {
		border-bottom: 1px solid #F2F2F2;
	}
	.card-border {
		padding-bottom: 20px;
	}
    .wrapper {
        padding: 20px 10px;
        box-sizing: border-box;
        border-radius: 10px;
        background-color: #FFFFFF;
        margin-top: 35px;
        h1 {
            position: absolute;
            top: -17px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 16px;
        }
        .service-intro {
            font-size: 14px;
            li {
                margin-bottom: 5px;
            }
        }
    }
	.container {
		padding: 10px 12px;
		background-color: #F2F5F7;
		.banner {
			width: 100%;
		}
		.tabs {
			height: 40px;
			background: #FFFFFF;
			border-radius: 8px;
			margin-top: 6px;
			display: flex;
			overflow: hidden;
			div {
				flex: 1;
				text-align: center;
				line-height: 40px;
				font-size: 16px;
				&.active {
					color: #FFF;
					background-color: #1f8aed;
				}
			}
		}
		.order-info {
			background: #FFFFFF;
			border-radius: 8px;
			margin-top: 10px;
			padding: 16px;
            .sy-tips {
                font-size: 12px;
                color: #FF9938;
                margin-top: 10px;
            }
			&-title {
				padding-bottom: 15px;
				.icon {
					width: 22px;
					height: 22px;
				}
				.title {
					font-size: 17px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #202020;
					margin: 0 10px 0 2px;
				}
				.tips {
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
				}
				.t1 {
					color: #FF9938;
				}
				.t2 {
					color: #AFAFAF;
				}
                .show-detail {
                    font-size: 12px;
                    position: absolute;
                    right: 0;
                    color: #2a97ef;
                }
			}
		}
		.button {
			height: 40px;
			margin-top: 32px;
			margin-bottom: 24px;
			position: relative;
			img {
				width: 343px;
				height: 40px;
			}
			span {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #FFFFFF;
			}
		}
		.marital-status {
			::v-deep .van-field__control--custom {
				display: flex;
				justify-content: end;
			}
		}
	}
    .ex-btn {
        width: 60px;
        height: 30px;
        font-size: 14px;
        border-radius: 5px;
        line-height: 30px;
        text-align: center;
        color: #FFFFFF;
        background-color: #29ace3;
        float: right;
        margin: 10px 14px 12px;
    }
    .ex-time {
        width: 60vw;
        height: 100%;
        overflow-y: scroll;
        span {
            width: 100%;
            font-size: 14px;
            padding: 10px;
            &.active {
                color: #1989fa;
            }
        }
    }
    .ex-date {
        width: 40vw;
        height: 100%;
        overflow-y: scroll;
        span {
            width: 100%;
            font-size: 14px;
            padding: 10px;
            background-color: rgba(175, 175, 175, .14);
            &.active {
                color: #1989fa;
                background-color: #FFFFFF;
            }
        }
    }
    .vimg {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 12px;
    }
</style>